/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Card, SvgIcon, Tooltip } from '@material-ui/core';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import CustomModal from '../../../framework/modal/modal';
import './tracking.scss';
import TrackingBanner from '../../../components/dashboard/Tracking/trackingBanner/trackingBanner';
import ShipmentDetails from '../../../components/dashboard/Tracking/shipmentDetails/shipmentDetails';

import DeliveryPhotographs from '../../../components/dashboard/Tracking/deliveryPhotographs/deliveryPhotographs';
import ChangeDeliveryAddress from '../../Tracking/ChangeDeliveryAddress/ChangeDeliveryAddress';

import { GET, POST } from '../../../api/axios';
import { useHistory } from 'react-router';
import TrackingDetails from '../../../components/dashboard/Tracking/TrackingDetails/TrackingDetails';

import Drawer from '@material-ui/core/Drawer';
import { ReactComponent as Ordernotes } from '../../../assets/images/order_notes.svg';

import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import TrackingFindShipment from '../dashboard/trackingFindShipment/TrackingFindShipmentMiddleware';
import OrderNotes from "../../../components/Tracking/trackingNotes/orderNotes"
const Tracking = (props) => {
  const [dialogStatus, setDialogStatus] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [Dialogpopup, setDialogpopup] = useState(false);
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [trackingStatus, setTrackingStatus] = useState('');
  const [currentDeliveryType, setcurrentDeliveryType] = useState('');
  const [showDeliveryChangeButton, setShowDeliveryChangeButton] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getShipmentTrackingDetails();
  }, []);

  useEffect(() => {
    if (
      shipmentDetails?.deliveryType?.toLowerCase() === 'doorstep' || 'depot' &&
      (trackingStatus?.toLowerCase()?.trim() === 'wehaveyourparcel' ||
        trackingStatus?.toLowerCase()?.trim() === 'orderreceived' ||
        trackingStatus?.toLowerCase()?.trim() === 'outfordelivery' ||
        trackingStatus?.toLowerCase()?.trim() === 'na' ||
        trackingStatus?.trim() === '')
    ) {
      setShowDeliveryChangeButton(true);
    } else {
      setShowDeliveryChangeButton(false);
    }
  }, [trackingStatus, shipmentDetails]);

  const getShipmentTrackingDetails = () => {
    const queryParams = history.location.search.split('=');
    GET(`${ServiceEndPoints.getShipmentTracking}${queryParams[1]}`)
      // GET(`${ServiceEndPoints.getShipmentTracking}${55500001476492}`)
      .then((res) => {
        (((res || {}).data || {}).shipmentEventModels || []).map((element, index) => {
          if (index === 0 && element?.shipmentEvent?.code!=='DNTR') {
            setTrackingStatus(
              element?.shipmentEvent?.shipHarmonisedEvents?.trackingPageStatus?.replace(/\s+/g, '').toLowerCase(),
            );
          }
        });

        setShipmentDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
        setShipmentDetails({});
      });
  };

  const cancelShipment = () => {
    const itemsToBeSelected = [];
    const item = {
      pickupAccount: shipmentDetails?.accountNumber,
      shipmentId: shipmentDetails?.shipmentNumber,
      postalCode: shipmentDetails?.consigneeAddress?.postalCode,
    };
    itemsToBeSelected.push(item);
    const payload = itemsToBeSelected;
    POST(ServiceEndPoints.cancelShipment, payload)
      .then((response) => {
        alert('Shipment successfully cancelled');
        history.goBack();
      })
      .catch((error) => {
        alert('Error in cancelling the shipment:' + payload[0].shipmentId);
      });
  };

  const handleDrawer = () => {
    open ? setOpen(false) : setOpen(true);
  };
  const onDeliveryTypeChange = (value) => {
    setcurrentDeliveryType(value);
  };
  const onDeliveryInstructionChnage = () => {
    // setDialogStatus(false);
    setTimeout(function () {
      getShipmentTrackingDetails();
    }, 2000);
  };

  return (
    <React.Fragment>
      <div>
        <Tooltip
          title="Shipment notes"
          arrow
          placement="bottom"
        >
          <button id="track-list-btn"
            className={`Track-list-icon`}
            onClick={handleDrawer}
            style={{ display: open ? 'none' : '', position: 'fixed' }}>
            <SvgIcon component={Ordernotes} />
          </button>
        </Tooltip>

        <Drawer
          BackdropProps={{ style: { background: '#D3D3D3 0% 0% no-repeat padding-box', opacity: 0.3 } }}
          className="track-list-drawer"
          anchor={'right'}
          open={open}
          title="Order notes"
        >
          <OrderNotes
            setOpen={setOpen}
            handleDrawer={handleDrawer}
            shipmentDetails={shipmentDetails}
          />
        </Drawer>
      </div>
      <Grid className="tracking-section">
        <Grid container direction="column" spacing={3} style={{ position: "relative" }}>
          <Grid item className="TrackingFindShipmentItem">
            <TrackingFindShipment />
          </Grid>
          <Grid item>
            <TrackingBanner />
          </Grid>
          <Grid item className="tracking-tabs">
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <Card className="customCard shipment-card">
                  <ShipmentDetails shipmentDetails={shipmentDetails} onDeliveryTypeChange={onDeliveryTypeChange} />
                </Card>
              </Grid>
              <Grid item xs={5}>
                <Card className="customCard">
                  <TrackingDetails shipmentDetails={shipmentDetails} trackingStatus={trackingStatus} />
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card style={{ maxWidth: '1000px' }} className="customCard">
                  <DeliveryPhotographs shipmentDetails={shipmentDetails} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="tracking-btn-section">
            <Grid container justify="flex-end" spacing={3}>
            {(trackingStatus === 'orderreceived' &&  shipmentDetails?.shipmentEventModels?.[0]?.shipmentEvent?.code !== "T415") && (
              // {trackingStatus === 'orderreceived' && (
                <Grid item>
                  <InputButton
                    label="Cancel shipment"
                    variant="outlined"
                    className="outlined-btn"
                    onClick={() => {
                      cancelShipment();
                    }}
                  />
                </Grid>
              )}
              {showDeliveryChangeButton && shipmentDetails?.consigneeAddress?.country === 'GB' && (
                <Grid item>
                  <InputButton
                    label="Change delivery instructions"
                    variant="outlined"
                    className="outlined-btn"
                    onClick={() => setDialogStatus(true)}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <CustomModal
          open={dialogStatus}
          title="Change delivery instruction"
          onClose={() => {
            setDialogStatus(false);
          }}
        >
          <ChangeDeliveryAddress
            closeDialog={() => {
              setDialogStatus(false);
            }}
            TitleDialog={() => {
              setDialogpopup(true);
            }}
            shipmentDetails={shipmentDetails}
            onChangeDeliveryAddress={onDeliveryInstructionChnage}
            current_DeliveryType={currentDeliveryType}
          />
        </CustomModal>
      </Grid>
      <Grid container>
        <CustomModal
          open={Dialogpopup}
          title="Change of delivery not available for this shipment"
          onClose={() => {
            setDialogpopup(false);
          }}
        >
          <Grid style={{ width: '375px', fontSize: '16px' }}>Change of delivery not available for this shipment</Grid>
        </CustomModal>
      </Grid>
    </React.Fragment>
  );
};

Tracking.propTypes = {};

export default Tracking;
