import React, { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';

import DashBoardAccordian from '../../../components/Tracking/DashBoardAccordian/DashBoardAccordian';
import { ChangeDeliveryInstrForm } from '../../../utils/formConfig/ChangeDeliveryInstr/ChangeDeliveryInstr';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { PUT, GET } from '../../../api/axios';
import { formatDateToSecondFormat, getDayOfTheWeek } from '../../../utils/helperFunctions';

import { changeDeliveryType } from '../../../utils/constants/constants';
import './ChangeDeliveryAddress.scss';

import SafePlaceLocation from '../../../components/Tracking/SafePlaceLocation';
import DeliverToServicePoint from '../../../components/Tracking/deliverToServicePoint';
import DeliverToDepot from '../../../components/Tracking/deliverServiceDepot';
import DeliveryButtonSet from '../../../components/Tracking/changeDeliveryButtons';

import './ChangeDeliveryAddress.scss';

import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import * as actions from '../../../store/actions/index';
import { useDispatch } from 'react-redux';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';
import { DELIVERY_TYPES, DELIVERY_STATUS, DELIVER_TYPE } from '../../../utils/constants/constants';


const { formField, formId } = ChangeDeliveryInstrForm;

const ChangeDeliveryAddress = (props) => {
  const { closeDialog, TitleDialog } = props;
  const formRef = useRef();
  const [initialValues, setIntialValue] = useState(null);
  const [offers, setOffers] = useState({});
  const [deliveryDates, setDeliveryDates] = useState(null);
  const [servicePointSelected, setServicePointSelected] = useState(null);
  const [_isDisableddeliveryType, set_isDisableddeliveryType] = useState(true);
  const [_isDisabledFordeliveryDueDate, set_isDisabledFordeliveryDueDate] = useState(true);
  const [DeliveryTypeValues, setDeliveryTypeValues] = useState('');
  const [OldDeliveryTypeValues, setOldDeliveryTypeValues] = useState('');
  const [changeDeliveryTypeDropdownValues, setchangeDeliveryTypeDropdownValues] = useState([]);
  const [changeDeliveryInstrSchema, setValidationSchema] = React.useState(getYupSchemaFromMetaData(formField, [], []));
  const [selectedID, setSelectedID] = useState(null);
  const dispatch = useDispatch();

  const setIntialValues = () => {
    const initialEmptyValues = {};
    for (let property in formField) {
      if (Object.prototype.hasOwnProperty.call(formField, property)) {
        const field = formField[property];
        if (field.props.type === 'checkbox' || field.props.type === 'radio') {
          initialEmptyValues[field.props.name] = field.value;
        } else {
          initialEmptyValues[field.props.name] = field.value || '';
        }
      }
    }
    setIntialValue(initialEmptyValues);
    dispatch(actions.setLoaderLayOver(false));
  };

  //Set value for selected serive point.
  const servicePointClicked = (servicePointDetails) => {
    setServicePointSelected(servicePointDetails);
    setSelectedID(servicePointDetails.servicePointID);
  };

  /**
   * Updates dropdown values and form field values for delivery types.
   * 
   * @param {Array} myArray - The array of delivery types to be set in the dropdown.
   * @param {Object} deliveryType - The current delivery type to be set in the form field.
   * @param {string} value - The value of the current delivery type.
   */
  const updateDropdownValues = (myArray, deliveryType, value) => {
    setchangeDeliveryTypeDropdownValues(myArray);
    formRef.current.setFieldValue('deliveryType', deliveryType);
    setOldDeliveryTypeValues(value);
  };

  //function to determine the postcode based on delivery type
  const getPostcode = (shipmentDetails) => {
    let consigneeAddressPostcode = shipmentDetails?.consigneeAddress?.postalCode;
    let recipientAddressPostcode = shipmentDetails?.recipientAddress?.postalCode;

    // Check delivery type and return appropriate postcode
    if (shipmentDetails?.deliveryType?.toLowerCase() === DELIVER_TYPE.DEPOT) {
      return recipientAddressPostcode;
    } else {
      return consigneeAddressPostcode;
    }
  }

  //Get all offers for the shipment.
  useEffect(() => {
    let postcode = getPostcode(props?.shipmentDetails);
    let url = `${ServiceEndPoints.getShipment}/${props.shipmentDetails.shipmentNumber}/offers?postcode=${postcode}`;
    dispatch(actions.setLoaderLayOver(true));
    GET(url)
      .then((response) => {
        setOffers(response.data);
        setAvailableDates(response.data);
        setIntialValues();
        let myArray = changeDeliveryType;

        if (response.data.leaveWithNeighbour === DELIVERY_STATUS.NULL) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.LEAVE_NEIGHBOUR;
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }
        if (response.data.preferredLocation === DELIVERY_STATUS.NULL) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.SAFE_LOCATION;
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }
        if (response.data.preferredDepot === DELIVERY_STATUS.NULL) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.DEPOT &&
              obj.name !== DELIVERY_TYPES.DEPOT_PIN &&
              obj.name !== DELIVERY_TYPES.DEPOT_AGE_VERIFICATION;
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }
        if (response.data.preferredDepot?.deliveryChoiceCategory === DELIVERY_STATUS.NONE) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.DEPOT_PIN &&
              obj.name !== DELIVERY_TYPES.DEPOT_AGE_VERIFICATION;
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }
        if (response.data.preferredDepot?.deliveryChoiceCategory === DELIVERY_STATUS.PIN) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.DEPOT &&
              obj.name !== DELIVERY_TYPES.DEPOT_AGE_VERIFICATION;
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }
        if (response.data.preferredDepot?.deliveryChoiceCategory === DELIVERY_STATUS.AGE) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.DEPOT_PIN &&
              obj.name !== DELIVERY_TYPES.DEPOT;
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }
        if (response.data.deliverToDoorstepOnly === null) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.DOORSTEP_ONLY &&
              obj.name !== DELIVERY_TYPES.DOORSTEP_AGE_VERIFICATION &&
              obj.name !== DELIVERY_TYPES.DOORSTEP_PIN
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }
        if (response.data.deliverToDoorstepOnly?.deliveryChoiceCategory === DELIVERY_STATUS.NONE) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.DOORSTEP_AGE_VERIFICATION &&
              obj.name !== DELIVERY_TYPES.DOORSTEP_PIN
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }
        if (response.data.deliverToDoorstepOnly?.deliveryChoiceCategory === DELIVERY_STATUS.AGE) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.DOORSTEP_ONLY &&
              obj.name !== DELIVERY_TYPES.DOORSTEP_PIN
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }
        if (response.data.deliverToDoorstepOnly?.deliveryChoiceCategory === DELIVERY_STATUS.PIN) {
          myArray = myArray.filter(function (obj) {
            return obj.name !== DELIVERY_TYPES.DOORSTEP_ONLY &&
              obj.name !== DELIVERY_TYPES.DOORSTEP_AGE_VERIFICATION
          });
          updateDropdownValues(myArray, props.current_DeliveryType, props.current_DeliveryType.value);
        }


        if (
          response.data.leaveWithNeighbour === DELIVERY_STATUS.NULL &&
          response.data.preferredServicePoint === DELIVERY_STATUS.NULL &&
          response.data.preferredLocation === DELIVERY_STATUS.NULL &&
          response.data.preferredDepot === DELIVERY_STATUS.NULL &&
          response.data.deliverToDoorstepOnly === DELIVERY_STATUS.NULL
        ) {
          // formRef.current.setFieldValue('deliveryType', { name: 'Select', value: '' });
          formRef.current.setFieldValue('deliveryType', props.current_DeliveryType);

        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setLoaderLayOver(false));
        closeDialog();
        TitleDialog();
      });
  }, []);

  const isDisabled = offers ? (
    offers.leaveWithNeighbour === DELIVERY_STATUS.NULL &&
    offers.preferredServicePoint === DELIVERY_STATUS.NULL &&
    offers.preferredLocation === DELIVERY_STATUS.NULL &&
    offers.preferredDepot === DELIVERY_STATUS.NULL &&
    offers.deliverToDoorstepOnly === DELIVERY_STATUS.NULL
  ) : true;

  const renderChangeAddressType = (values, formField) => {
    if (values.deliveryType) {
      switch (values.deliveryType.value) {
        case DELIVERY_TYPES.SAFE_LOCATION:
          return <SafePlaceLocation set_isDisableddeliveryType={set_isDisableddeliveryType} formField={formField} values={values} />
       
        case DELIVERY_TYPES.DEPOT:
        case DELIVERY_TYPES.DEPOT_PIN:
        case DELIVERY_TYPES.DEPOT_AGE_VERIFICATION:
          return (
            <DeliverToDepot
              fromValues={values}
              shipmentDetails={props.shipmentDetails}
              rowClicked={(row) => {
                setSelectedID(row.depotId);
              }}
              selectedID={selectedID}
            />
          );

        default:
          break;
      }
    }
  };

  const setAvailableDates = (data) => {
    if (data && data.preferredDay) {
      const validDays = data.preferredDay.validDays.map((day) => {
        return {
          value: day,
          label: `${getDayOfTheWeek(day)},${formatDateToSecondFormat(day)}`,
        };
      });
      setDeliveryDates(validDays);
    }
  };

  const onDeliveryMethodSubmit = (formValues, errors) => {
    if (Object.keys(errors).length === 0 && errors.constructor === Object) {
      const { shipmentDetails } = props;
      let url = `${ServiceEndPoints.getShipment}/${props.shipmentDetails.shipmentNumber}/`;
      let payLoad = {};

      switch (formValues.deliveryType.value) {
        case DELIVERY_TYPES.SAFE_LOCATION:
          url += 'preferredLocation';
          payLoad = {
            preferredLocation: parseInt(formValues.safePlaceLocation.value),
            postcode: getPostcode(shipmentDetails)
          };
          break;
        case DELIVERY_TYPES.LEAVE_NEIGHBOUR:
          url += 'leaveWithNeighbour';
          payLoad = {
            leaveWithNeighbour: true,
            postcode: getPostcode(shipmentDetails)
          };
          break;
        case DELIVERY_TYPES.DOORSTEP_ONLY:
        case DELIVERY_TYPES.DOORSTEP_PIN:
        case DELIVERY_TYPES.DOORSTEP_AGE_VERIFICATION:
          url += 'orders/deliverToDoorstepOnly';
          payLoad = {
            postcode: getPostcode(shipmentDetails)
          };
          break;
        case DELIVERY_TYPES.DEPOT:
        case DELIVERY_TYPES.DEPOT_PIN:
        case DELIVERY_TYPES.DEPOT_AGE_VERIFICATION:
          url += 'preferredDepot';
          payLoad = {
            collectionDate: offers?.preferredDepot?.collectionDates?.[0],
            postcode: getPostcode(shipmentDetails),
          };
          break;
        default:
          break;
      }

      PUT(url, payLoad)
        .then((res) => {
          // alert('Delivery method changed successfully');
          dispatch(actions.setAppSnackBarData({ msg: 'Change delivery instruction updated successfully' }));
          setOldDeliveryTypeValues(formValues.deliveryType.value);
          set_isDisableddeliveryType(true);
          props.onChangeDeliveryAddress();
          closeDialog();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [{ type: 'error', msg: 'The form has errors, please correct and try again' }],
        }),
      );
    }
  };

  const onDeliveryMethodCancel = (setFieldValue) => {
    setFieldValue('deliveryType', '');
    setFieldValue('safePlaceLocation', '');
    setFieldValue('safePlaceLocationAdditionalText', '');
    setFieldValue('leaveWithNeighbourText', '');
    set_isDisableddeliveryType(true);
  };

  const onDeliveryDateSubmit = (formValues) => {
    const url = `${ServiceEndPoints.getShipment}/${props.shipmentDetails.shipmentNumber}/preferredDay`;
    const payload = {
      date: formValues.deliveryDueDate,
      postcode: `${props.shipmentDetails.consigneeAddress.postalCode}`,
    };
    PUT(url, payload)
      .then((res) => {
        // alert('Delivery date changed successfully');
        dispatch(actions.setAppSnackBarData({ msg: 'Change delivery instruction updated successfully' }));
        props.onChangeDeliveryAddress();
        set_isDisabledFordeliveryDueDate(true);
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDeliveryDateCancel = (setFieldValue) => {
    setFieldValue('deliveryDueDate', '');
    set_isDisabledFordeliveryDueDate(true);
  };

  const getCurrentDueDate = () => {
    const { shipmentDetails } = props;
    let currentString = '';
    if (shipmentDetails.dueDate) {
      let d = new Date(shipmentDetails.dueDate);
      let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      let mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
      let da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d);
      let weekDay = new Intl.DateTimeFormat('en', { weekday: 'long' }).format(d);

      currentString = `${da}/${mo}/${ye}(${weekDay})`;
    }
    return currentString;
  };

  const handleChange = (e) => {
    set_isDisabledFordeliveryDueDate(false);
  };

  useEffect(() => {
    if (formRef.current?.values?.deliveryType) {
      if (OldDeliveryTypeValues === formRef.current?.values?.deliveryType?.value) {
        set_isDisableddeliveryType(true);
      } else {
        set_isDisableddeliveryType(false);
      }
    } else {
      set_isDisableddeliveryType(true);
    }
  }, [DeliveryTypeValues, OldDeliveryTypeValues]);

  return (
    <div>
      {initialValues ? (
        <div style={{ width: '812px' }}>
          <Formik initialValues={initialValues} innerRef={formRef} validationSchema={changeDeliveryInstrSchema}>
            {({ values, setFieldValue, errors }) => {
              console.log('values.deliveryType::: ', values.deliveryType);
              if (values.deliveryType) {
                setDeliveryTypeValues(values.deliveryType);
              }
              return (
                <Form id={formId}>
                  {deliveryDates && props?.shipmentDetails.deliveryType?.toLowerCase() !== DELIVER_TYPE.DEPOT ? (
                    <DashBoardAccordian heading="Change delivery date" secondaryheading={getCurrentDueDate()}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormRenderer
                            {...formField.deliveryDueDate.props}
                            data={deliveryDates}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <DeliveryButtonSet
                            isDisabled={_isDisabledFordeliveryDueDate}
                            onSubmit={() => onDeliveryDateSubmit(values)}
                            onCancel={() => onDeliveryDateCancel(setFieldValue)}
                          />
                        </Grid>
                      </Grid>
                    </DashBoardAccordian>
                  ) : null}
                  <DashBoardAccordian
                    heading="Change delivery method"
                    secondaryheading={
                      props.current_DeliveryType.name === 'Select'
                        ? 'Deliver to doorstep only'
                        : props.current_DeliveryType.name
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <FormRenderer
                          {...formField.deliveryType.props}
                          data={changeDeliveryTypeDropdownValues}
                          fullWidth
                          isDisabled = {isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <DeliveryButtonSet
                          isDisabled={_isDisableddeliveryType}
                          onSubmit={() => onDeliveryMethodSubmit(values, errors)}
                          onCancel={() => onDeliveryMethodCancel(setFieldValue)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {renderChangeAddressType(values, formField)}
                      </Grid>
                    </Grid>
                  </DashBoardAccordian>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : null}
    </div>
  );
};

export default ChangeDeliveryAddress;
